.auth-layout {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-layout .ant-card {
    width: 40rem;
}
