@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

:root {
    --primary-color: #232a56;
}

/* Customize AntD */
.theme {
    --ant-font-family: "Open Sans", sans-serif;
    --ant-font-weight-strong: 500;

    --ant-color-primary: #232a56;
    --ant-color-primary-hover: #252c5b;
    --ant-color-text: #000;

    --ant-color-border: #d9d9d9;

    --ant-border-radius: 4px;
}

/* Page Header */
.ant-page-header {
    padding-inline: 30px;
}

.ant-page-header-footer {
    margin-block-start: 0px;
}

/* Main Header */
.ant-layout-header {
    border-bottom: 1px solid #ddd;
    padding-inline: 30px;
    background: var(--ant-color-primary) !important;
    color: #fff;
    display: flex;
    align-items: center;
}

.ant-layout-header .ant-menu {
    background: transparent !important;
}

.ant-layout-header .ant-menu-item {
    color: #fff;
}

/* Header -> Switch User */
.switch-modal .ant-modal-body {
    padding: 1em;
}

.switch-account-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 0.5em;
}
