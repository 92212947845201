.ant-page-header {
    padding-block: 1rem;
    background: #fff !important;
}

.ant-page-header .ant-tabs-nav {
    margin-bottom: 0px !important;
}

.ant-page-header .ant-steps {
    margin-bottom: 1rem;
}

/* Job Card */
.job-card {
    margin-bottom: 0.5rem !important;
    cursor: pointer;
}

.job-card .attributes {
    gap: 0.5rem;
}

.ant-steps-item-content {
    text-transform: capitalize;
}
