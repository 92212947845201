/* .dashboard-layout {
    height: 100vh;
    width: 100vw;
} */

/// Main Theme ///
$primary-color: #519ed7;

$font-size: 0.9rem;
$sidebar-width: 250px;
$text-color: #6b778c;
$heading-color: #172b4d;
$link-color: #42526e;
$border-color: #e8edeb;

/// Boostrap Overrides
$theme-colors: (
    "primary": $primary-color,
    "danger": #dc3545,
    "success": #28a745,
    "warning": #ffc107,
    "secondary": #6c757d,
    "info": #17a2b8,
);
$border-radius: 5px;
$dropdown-font-size: $font-size;
$dropdown-border-color: $border-color;
$btn-font-weight: 500;

$card-border-color: $border-color;
$nav-tabs-border-color: $border-color;
// Boostrap Form
$input-border-width: 2px;
$input-border-color: #eee;

// $form-label-color: #172B4D;
$form-label-margin-bottom: 0px;
$form-label-font-weight: 600;

$modal-content-border-width: 0px;
$modal-content-border-radius: $border-radius;
$progress-bar-bg: $primary-color;

$breadcrumb-divider: "\203A";

@import "bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

.rmsc {
    --rmsc-border: $border-color;
}

body {
    font-family: "Open Sans", sans-serif;
    padding: 0px;
    margin: 0px;
    background: rgb(248, 249, 250);
    // background: #fbfbfb;
    font-size: $font-size;
    color: $text-color;
}

.rdt_Table {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    overflow: hidden;
}
.rdt_TableCol {
    font-weight: 700;
    text-transform: uppercase;
}
.rdt_TableCell {
    font-weight: 500;
}

.error-text {
    color: #dc3545 !important;
    font-weight: 500;
}

.avatar {
    border-radius: 50%;
    border: 2px solid $border-color;
    margin-right: 0.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $heading-color;
}

.nav-tabs .nav-link {
    font-size: calc($font-size + 0.1rem);
    border-color: transparent;
    font-weight: 500;
    &:hover,
    &:focus,
    &:active {
        border-color: transparent;
    }
}

.form-control:focus {
    box-shadow: none !important;
}

.app-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
}

// Form
label {
    font-weight: 500;
}

// Button
.btn-outline-primary {
    border-width: 2px;
    &:hover {
        color: #fff !important;
    }
}
.btn.btn-primary {
    color: #fff;
}
.btn.btn-block {
    width: 100%;
}

// Navtabs
.nav-tabs .nav-link[data-active="true"],
.nav-tabs .nav-link.active {
    border-color: transparent;
    border-bottom: 2px solid var(--ant-color-primary);
}

/// DropDown
.dropdown .dropdown-toggle.no-caret::after {
    display: none;
}

layout {
    height: 100%;
    flex: 1;
}

.page-header {
    margin: -1rem;
    padding: 1rem 0.5rem;
    margin-bottom: 1rem;
    // width: 100%;
    .nav.nav-tabs {
        border-bottom: transparent;
        margin-bottom: -1rem;
        border-bottom: 1px solid $border-color;
        margin: 1rem -1rem -1rem -1rem;
    }
}

.home-layout {
    height: 100vh;
    overflow-y: scroll;
}

.dashboard-layout {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    header {
        height: 4rem;
        background: #fff;
        border-bottom: 1px solid #e7edeb;
        // box-shadow: 0px 1px 1px 1px rgb(9 30 66 / 5%);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        z-index: 999;
        .right-side {
            padding: 0px 1rem;
            display: flex;
            align-items: center;
        }
        .header-item {
            margin: 0rem 0.5rem;
            i {
                font-size: 1.2rem !important;
            }
        }

        .logo-container {
            width: $sidebar-width;
            text-align: center;
        }
        .search-container {
            width: 300px;
            display: flex;
            align-items: center;
            border: 1px solid $border-color;
            border-radius: $border-radius;
            padding: 0.3rem 1rem;
            input {
                all: inherit;
                border: none;
                height: 100%;
            }
        }
        .account-menu {
            .icon {
                width: 2rem;
                font-size: 1rem;
                margin-right: 0.5rem;
            }
            .dropdown-item {
                color: $link-color;
                font-weight: 500;
            }
            .toggle {
                background: transparent;
                border: none;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: $link-color;
                font-weight: 500;
                img {
                    width: 2.5rem;
                    height: 2.5rem;
                    border-radius: 50%;
                    border: 2px solid $border-color;
                    margin-right: 0.5rem;
                }
            }
        }
    }
    div.sidebar {
        background: #fff;
        border-right: 1px solid $border-color;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .nav-link {
            color: $link-color;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0em;
            padding-block: 1em;
            i {
                font-size: 1.2rem;
            }
            &[data-active="true"] {
                i {
                    color: var(--ant-color-primary);
                }
            }
        }
        .nav-heading {
            color: #333;
            font-size: 12px;
            text-transform: uppercase;
            padding: 0.5rem;
            padding-bottom: 0px;
        }
    }
    div.content {
        padding: 0.5rem 1rem;
        flex: 1;
    }
}

.job-view {
    margin: -0.5rem -1rem;
    & > .header {
        display: flex;
        flex-direction: column;
        background: #fff;
        .info {
            padding: 1.5rem 2rem;
        }
    }
}

/// JOB Board
.job-board {
    flex: 1 1 auto;
    display: flex;
    max-height: max-content;
    overflow-y: scroll;
    background: #fafafa;
    margin: -0.5rem;
    .board-stage {
        width: 20em;
        min-width: 20em;
        display: inline;
        margin-right: 1em;
        vertical-align: top;
        .header {
            padding: 0.2em 0.5em;
            border-radius: $border-radius;
            border: 1px solid $border-color;
            background: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title {
                text-transform: uppercase;
                font-weight: 600;
                margin-bottom: 0px;
                display: flex;
                align-items: center;
                .count {
                    margin-left: 0.5rem;
                    font-weight: 700;
                }
            }
        }
    }

    .board-application {
        border-radius: $border-radius;
        border: 1px solid $border-color;
        background: #fff;
        // height: 100px;
        margin: 0.5rem 0rem 1rem 0rem;
        cursor: pointer;

        .user-avatar {
            width: 3rem;
            height: 3rem;
            margin-right: 1rem;
            border-radius: 50%;
            border: 2px solid $border-color;
        }

        .stats {
            border-top: 1px solid $border-color;
            padding: 0.5rem 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.application-view {
    .score-container {
        .score {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 2rem;
        }
        .score-item {
            .title {
                margin-bottom: 0px;
                font-weight: 500;
            }
            .progress {
                height: 0.3rem;
            }
            & + .score-item {
                margin-top: 1rem;
            }
        }
    }

    .comment {
        display: flex;
        align-items: flex-start;
        .username {
            font-weight: 600;
            margin-bottom: 0px;
            font-size: 0.9rem;
        }
        .avatar {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            margin-right: 0.5rem;
            border: 2px solid $border-color;
        }
        .content {
            padding: 0.5rem 1rem;
            background: #f7f7f7 !important;
            border-radius: $border-radius;
            flex: 1;
            display: flex;
            flex-direction: column;
        }
        & + .comment {
            margin-top: 0.5rem;
        }
    }
    .comment-box {
        display: flex;
        justify-content: space-between;
        margin: -1rem;
        background: #f7f7f7;
        padding: 1rem;
    }
}

.role-item {
    border: 1px solid #ddd;
    margin: 0.5rem 0rem;
    padding: 1rem;
    border-radius: $border-radius;
    border: 1px solid $border-color;
    cursor: pointer;
    .name {
        margin-top: 0px;
        margin-bottom: 0px;
        font-weight: 600;
    }
}

.page {
    padding: 1rem;
    flex: 1;
    max-height: calc(100vh - 3.2rem);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    &.no-padding {
        padding: 0px;
    }
    .ant-page-header {
        margin: -1rem;
        margin-bottom: 1rem;
    }
}

.sub-sidebar {
    width: 15rem;
    background: #fff;
    padding: 1rem;
    border-right: 1px solid $border-color;
    .heading {
        margin: 0rem -1rem;
        border-bottom: 1px solid #eee;
        padding: 0.5rem;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 700;
    }
    .nav-link {
        color: $link-color;
        font-weight: 500;
        padding: 0.5rem 0rem;
    }
}

.pipeline-editor {
    .stage {
        padding: 1rem;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        background: #fff;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        position: relative;
        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid #ddd;
            margin: -1rem;
            padding: 0.5rem;
            margin-bottom: 0rem;
            .name {
                font-weight: 600;
                margin-bottom: 0px;
                border: none;
                flex: 1;
                font-size: 1rem;
                padding: 0px;
                &:hover,
                &:active,
                &:focus {
                    border: none;
                    box-shadow: none;
                }
            }
            // .btn{
            //   position: absolute;
            //   top: -1rem;
            //   right: -1rem;
            //   background: #fff;
            //   font-size: 1.2rem;
            //   border-radius: 50%;
            // }
        }

        .score-cards-list {
            display: flex;
            flex-wrap: wrap;
        }
        .score-card {
            display: flex;
            align-items: center;
            background: #f9f5f5;
            margin-right: 0.5rem;
            border-radius: $border-radius;
            overflow: hidden;
            // align-items: stretch;
            .score {
                text-align: center;
                // background: $primary-color;
                padding: 0.2rem;
                font-weight: 700;
                // color: #fff;
                min-width: 2rem;
            }
            .title {
                display: flex;
                margin: 0rem 0.5rem;
                font-size: 0.9rem;
                margin-bottom: 0rem;
                flex: 1;
            }
        }
    }

    > .stage {
        border: 1px solid $border-color;
        border-radius: $border-radius;
        padding: 1rem;
        background: #fff;
        margin-bottom: 1rem;
        .name {
            font-weight: 600;
            margin-bottom: 0px;
            border: none;
            flex: 1;
            font-size: 1.2rem;
        }

        .members-list {
            display: inline;
        }

        .member {
            display: inline-flex;
            align-items: center;
            padding: 0rem 0.5rem 0rem 0rem;
            background: #fafafa;
            border-radius: 30px;
            align-items: center;
            margin-right: 1rem;
            flex-wrap: wrap;
            margin-bottom: 0.4rem;
            .avatar {
                width: 2.2rem;
                height: 2.2rem;
                border: none;
            }
            .member-name {
                flex: 1;
                margin-bottom: 0px;
            }
        }
        .score-card {
            display: inline-flex;
            align-items: center;
            padding: 0rem 0.5rem 0rem 0rem;
            background: #ebebeb;
            align-items: center;
            margin-right: 1rem;
            flex-wrap: wrap;
            margin-bottom: 0.4rem;
            .score {
                width: 2rem;
                height: 2rem;
                background: $primary-color;
                color: #fff;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .title {
                margin-bottom: 0px;
                flex: 1;
                margin: 0rem 0.5rem;
            }
        }
    }
    .stage-add {
        cursor: pointer;
        color: #172b4d;
        border: 2px dashed #172b4d;
        border-radius: $border-radius;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5rem;
        font-size: 1.5rem;
    }
}

.permissions-table {
    .title {
        font-weight: 600;
        margin: 0px;
        cursor: pointer;
    }
    .group {
        display: flex;
        flex-direction: column;

        > .title {
            font-weight: 700;
        }
    }
}

.stepper {
    .nav-link {
        font-weight: 500;
        font-size: 1.1rem;
        &.active {
            color: #fff;
            background: $primary-color;
        }
    }
}

.tox.tox-tinymce-aux {
    display: none;
}

.react-select__control {
    border-radius: $border-radius;
    border-width: $input-border-width !important;
    border-color: $input-border-color !important;
}
